<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="标题" prop="title">
        <el-input v-model="info.title" placeholder="请输入标题"></el-input>
      </el-form-item>
<!--      <el-form-item label="icp">-->
<!--        <el-input v-model="info.icp" placeholder="请输入icp"></el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="版权信息">-->
<!--        <el-input v-model="info.copyright" placeholder="请输入版权信息"></el-input>-->
<!--      </el-form-item>-->
      <el-form-item label="微信收款码" prop="wx_payment_code">
        <SelectImgs :selectNum="1" :selectData="[this.info.wx_payment_code]" :selectFunc="picChoose" :idx="1"></SelectImgs>
      </el-form-item>
      <el-form-item label="支付宝收款码" prop="ali_payment_code">
        <SelectImgs :selectNum="1" :selectData="[this.info.ali_payment_code]" :selectFunc="picChoose" :idx="2"></SelectImgs>
      </el-form-item>
      <el-form-item label="第三方收款名称">
        <el-input v-model="info.payment_name" placeholder="请输入名称"></el-input>
      </el-form-item>
      <el-form-item label="第三方收款码" prop="payment_code">
        <SelectImgs :selectNum="1" :selectData="[this.info.payment_code]" :selectFunc="picChoose" :idx="3"></SelectImgs>
      </el-form-item>
      <el-form-item label="转账信息">
        <el-input type="textarea" rows="4" v-model="info.bank_code" placeholder="请输入收款账号信息"></el-input>
      </el-form-item>
<!--      <el-form-item label="SEO标题">-->
<!--        <el-input v-model="info.seo_title" placeholder="请输入SEO标题"></el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="SEO关键词">-->
<!--        <el-input v-model="info.seo_keywords" placeholder="请输入SEO关键词"></el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="SEO描述">-->
<!--        <el-input type="textarea" :rows="3" v-model="info.seo_desc" placeholder="请输入SEO描述"></el-input>-->
<!--      </el-form-item>-->
      <!--el-form-item label="手机号">
        <el-input v-model="info.mobile" placeholder="请输入手机号"></el-input>
      </el-form-item-->
      <el-form-item label="用户协议">
        <wang-edit :txt="info.agreement_user" :callback="setText" :idx="1"></wang-edit>
      </el-form-item>
      <el-form-item label="隐私协议">
        <wang-edit :txt="info.agreement_secret" :callback="setText" :idx="2"></wang-edit>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

import WangEdit from "@/components/WangEdit";
import SelectImgs from '../../components/SelectImgs'
  export default {
    data() {
      return {
        info: {
          title: '',
          icp: '',
          copyright: '',
          payment_name: '',
          payment_code: '',
          wx_payment_code: '',
          ali_payment_code: '',
          bank_code: '',
          agreement_user: '',
          agreement_secret: '',
          // seo_title: '',
          // seo_keywords: '',
          // seo_desc: '',
          mobile: ''
        },
        source_domain: this.config.SOURCE_DOMAIN,
        rules: {
          title: [
            { required: true, message: '请输入标题', trigger: 'blur' }
          ]
        }
      };
    },
    created() {
      this.getInfo()
    },
    mounted() {
    },
    components: {
      SelectImgs,
      WangEdit
    },
    computed: {
    },
    methods: {
      setText(txt,idx) {
        if(idx==1) {
          this.info.agreement_user = txt
        } else {
          this.info.agreement_secret = txt
        }
      },
      picChoose(data,idx) {
        if(idx==1) {
          this.info.wx_payment_code = data.pic
        } else if(idx==2) {
          this.info.ali_payment_code = data.pic
        } else if(idx==3) {
          this.info.payment_code = data.pic
        }
      },
      getInfo() {
        var that = this
        this.$api.merchant.sysDetail( function (res) {
          if(res.errcode == 0) {
            that.info = res.data
          }
        })
      },
      submitForm(formName) {
        var that = this
        this.$refs[formName].validate((valid) => {
          if (valid) {
            var param = {
              title: that.info.title,
              icp: that.info.icp,
              copyright: that.info.copyright,
              wx_payment_code: that.info.wx_payment_code,
              ali_payment_code: that.info.ali_payment_code,
              agreement_user: that.info.agreement_user,
              agreement_secret: that.info.agreement_secret,
              payment_name: that.info.payment_name,
              payment_code: that.info.payment_code,
              mobile: that.info.mobile,
              bank_code: that.info.bank_code
            }
            this.$api.merchant.sysEdit(param,function (res) {
              if(res.errcode == 0) {
                that.success(res.errmsg)
              } else {
                that.fail(res.errmsg)
              }
            })
          } else {
            return false;
          }
        });
      },
    }
  };
</script>
<style scoped>
  .box {
    background-color: #fff;
    padding: 20px;
    border-radius: 6px;
    min-height: 500px;
  }
</style>
